(function (cjs, an) {

var p; // shortcut to reference prototypes
var lib={};var ss={};var img={};
lib.ssMetadata = [
		{name:"index_atlas_1", frames: [[877,189,53,75],[932,189,21,31],[556,141,319,133],[556,0,331,139],[955,189,4,40],[695,276,212,46],[0,260,240,46],[474,276,219,46],[877,141,147,46],[0,308,209,46],[211,308,150,46],[242,260,230,46],[300,212,251,46],[0,212,298,46],[363,308,104,46],[0,0,554,210]]}
];


(lib.AnMovieClip = function(){
	this.actionFrames = [];
	this.ignorePause = false;
	this.gotoAndPlay = function(positionOrLabel){
		cjs.MovieClip.prototype.gotoAndPlay.call(this,positionOrLabel);
	}
	this.play = function(){
		cjs.MovieClip.prototype.play.call(this);
	}
	this.gotoAndStop = function(positionOrLabel){
		cjs.MovieClip.prototype.gotoAndStop.call(this,positionOrLabel);
	}
	this.stop = function(){
		cjs.MovieClip.prototype.stop.call(this);
	}
}).prototype = p = new cjs.MovieClip();
// symbols:



(lib.CachedBmp_16 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(0);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_15 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(1);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_14 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(2);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_13 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(3);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_12 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(4);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_11 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(5);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_10 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(6);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_9 = function() {
	this.initialize(img.CachedBmp_9);
}).prototype = p = new cjs.Bitmap();
p.nominalBounds = new cjs.Rectangle(0,0,2589,40);


(lib.CachedBmp_8 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(7);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_7 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(8);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_6 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(9);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_5 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(10);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_4 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(11);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_3 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(12);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_2 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(13);
}).prototype = p = new cjs.Sprite();



(lib.CachedBmp_1 = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(14);
}).prototype = p = new cjs.Sprite();



(lib.logoSeven = function() {
	this.initialize(ss["index_atlas_1"]);
	this.gotoAndStop(15);
}).prototype = p = new cjs.Sprite();
// helper functions:

function mc_symbol_clone() {
	var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
	clone.gotoAndStop(this.currentFrame);
	clone.paused = this.paused;
	clone.framerate = this.framerate;
	return clone;
}

function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
	var prototype = cjs.extend(symbol, cjs.MovieClip);
	prototype.clone = mc_symbol_clone;
	prototype.nominalBounds = nominalBounds;
	prototype.frameBounds = frameBounds;
	return prototype;
	}


(lib.stripe = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// stripe
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#5A756B").beginStroke().moveTo(-41,45).lineTo(-41,-45).lineTo(41,-45).lineTo(41,45).closePath();

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-41,-45,82.1,90);


(lib.sign_out_text = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// SignOutText
	this.instance = new lib.CachedBmp_16();
	this.instance.setTransform(0,0,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.sign_out_text, new cjs.Rectangle(0,0,26.5,37.5), null);


(lib.sign_out_background = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// SignOutBackground
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#990000").beginStroke().moveTo(-20,30).lineTo(-20,-30).lineTo(20,-30).lineTo(20,30).closePath();
	this.shape.setTransform(20,30);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.sign_out_background, new cjs.Rectangle(0,0,40,60), null);


(lib.pseudo_after = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// MenuPseudoAfter
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("rgba(134,126,109,0.498)").beginStroke().moveTo(-68.6,25).lineTo(-68.6,-25).lineTo(68.6,-25).lineTo(68.6,25).closePath();
	this.shape.setTransform(68.6,25);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.pseudo_after, new cjs.Rectangle(0,0,137.2,50), null);


(lib.menu_line = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// line
	this.shape = new cjs.Shape();
	this.shape.graphics.beginLinearGradientFill(["#5BC3BE","#1F4F6E"],[0,1],-680,0,680,0).beginStroke().moveTo(-680,1).lineTo(-680,-1).lineTo(680,-1).lineTo(680,1).closePath();
	this.shape.setTransform(680,1);

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.menu_line, new cjs.Rectangle(0,0,1360,2), null);


(lib.logo_small = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// logo
	this.instance = new lib.logoSeven();
	this.instance.setTransform(0,0,0.352,0.352);

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.logo_small, new cjs.Rectangle(0,0,195,73.9), null);


(lib.ClipGroup_1 = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Camada_2 (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.moveTo(-297.6,420.9).lineTo(-297.6,-420.9).lineTo(297.6,-420.9).lineTo(297.6,420.9).closePath();
	mask.setTransform(297.625,420.925);

	// Camada_3
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-5,6).curveTo(-5.7,5,-5.9,3.9).curveTo(-6,2.9,-6.1,0.8).lineTo(-6.1,-8.2).lineTo(-4.1,-8.2).lineTo(-4.1,1.5).curveTo(-4.1,3.7,-3.4,4.8).curveTo(-2.4,6.5,-0,6.5).curveTo(2.4,6.5,3.5,4.9).curveTo(4.1,3.8,4.1,1.4).lineTo(4.1,-8.2).lineTo(6.1,-8.2).lineTo(6.1,0.7).curveTo(6.1,4.6,5,6).curveTo(3.5,8.1,-0,8.2).curveTo(-3.6,8.2,-5,6).closePath();
	this.shape.setTransform(401.7,394.65);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-6,8).lineTo(-6,-8).lineTo(0.3,-8).curveTo(2.7,-8,3.7,-7.4).curveTo(5.4,-6.4,5.4,-4.2).curveTo(5.4,-1.3,2.6,-0.6).curveTo(4.2,-0.3,5.2,0.9).curveTo(6,2,6,3.6).curveTo(6,6.2,3.9,7.4).curveTo(2.7,8,-0.4,8).closePath().moveTo(-4,6.3).lineTo(-0.8,6.3).curveTo(1.5,6.3,2.4,5.9).curveTo(3.9,5.3,3.9,3.5).curveTo(3.9,0.4,0.2,0.4).lineTo(-4,0.4).closePath().moveTo(-4,-1.3).lineTo(-0.4,-1.3).curveTo(1.3,-1.3,2.1,-1.7).curveTo(3.4,-2.3,3.5,-4).curveTo(3.4,-4.9,2.8,-5.6).curveTo(2.1,-6.3,-0.2,-6.3).lineTo(-4,-6.3).closePath();
	this.shape_1.setTransform(385.25,394.5);

	var maskedShapeInstanceList = [this.shape,this.shape_1];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.ClipGroup_1, new cjs.Rectangle(379.3,386.5,28.5,16.30000000000001), null);


(lib.ClipGroup = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Camada_2 (mask)
	var mask = new cjs.Shape();
	mask._off = true;
	mask.graphics.moveTo(-297.6,420.9).lineTo(-297.6,-420.9).lineTo(297.6,-420.9).lineTo(297.6,420.9).closePath();
	mask.setTransform(297.625,420.925);

	// Camada_3
	this.instance = new lib.CachedBmp_13();
	this.instance.setTransform(327.4,386.15,0.5,0.5);

	var maskedShapeInstanceList = [this.instance];

	for(var shapedInstanceItr = 0; shapedInstanceItr < maskedShapeInstanceList.length; shapedInstanceItr++) {
		maskedShapeInstanceList[shapedInstanceItr].mask = mask;
	}

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.ClipGroup, new cjs.Rectangle(327.4,386.2,165.5,69.5), null);


(lib.botoes = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// Camada_1
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-236.1,78).lineTo(-236.1,-78).lineTo(236.1,-78).lineTo(236.1,78).closePath();
	this.shape.setTransform(236.05,78.025);
	this.shape._off = true;

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(3).to({_off:false},0).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,472.1,156.1);


(lib.background_dynamic = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {capa:0,projeto:1,localizacao:2,projetistas:3,arquitetura:4,implantacoes:5,plantas:6,sustentabilidade:7,diferenciais:8,institucional:9};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_0 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(10));

	// background
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill("#7BA295").beginStroke().moveTo(-960,500).lineTo(-960,-500).lineTo(960,-500).lineTo(960,500).closePath();
	this.shape.setTransform(960,500);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.beginFill("#C4BAA2").beginStroke().moveTo(-502.2,500).lineTo(-502.2,-500).lineTo(502.2,-500).lineTo(502.2,500).closePath();
	this.shape_1.setTransform(959.5984,500,1.9117,1);

	this.shape_2 = new cjs.Shape();
	this.shape_2.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-960,500).lineTo(-960,-500).lineTo(960,-500).lineTo(960,500).closePath();
	this.shape_2.setTransform(960,500);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape}]}).to({state:[]},1).to({state:[{t:this.shape}]},1).to({state:[{t:this.shape}]},1).to({state:[]},1).to({state:[{t:this.shape_1}]},1).to({state:[]},1).to({state:[{t:this.shape_1}]},1).to({state:[]},1).to({state:[{t:this.shape_2}]},1).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-0.4,0,1920.4,1000);


(lib.background = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(0,0,0,0);


(lib.arrow = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// arrow
	this.shape = new cjs.Shape();
	this.shape.graphics.beginLinearGradientFill(["#5BC3BE","#FFFFFF","#5BC3BE","#1F4F6E"],[0,0,0,1],-24,0,24,0).beginStroke().moveTo(-24,12).lineTo(0,-12).lineTo(24,12).closePath();

	this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-24,-12,48,24);


(lib.stripe_up = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {"in":0,out:10};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_9 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 * Class reference.
		 */
		const AllTapParent = window.parent.AllTap;
		
		
		AllTapParent.emit('animateend', {in: true});
	}
	this.frame_19 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		
		/**
		 *
		 * Class reference.
		 */
		const AllTapParent = window.parent.AllTap;
		
		
		AllTapParent.emit('animateend', {out: true});
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).wait(9).call(this.frame_9).wait(10).call(this.frame_19).wait(1));

	// stripe
	this.instance = new lib.stripe("synched",0);
	this.instance.setTransform(0,45);

	this.timeline.addTween(cjs.Tween.get(this.instance).to({y:-40},5).to({y:-35},2).to({y:-45},2).to({startPosition:0},1).to({y:-35},2).to({y:-40},2).to({y:45},5).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-41,-90,82.1,180);


(lib.menu_background = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// menu_line
	this.instance = new lib.menu_line();
	this.instance.setTransform(1240,-79,1,1,0,0,0,680,1);
	this.instance.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// background
	this.shape = new cjs.Shape();
	this.shape.graphics.beginFill().beginStroke("#61796D").setStrokeStyle(2,1,1).moveTo(-960,0).lineTo(960,0);
	this.shape.setTransform(960,-80);

	this.shape_1 = new cjs.Shape();
	this.shape_1.graphics.beginFill("#7BA295").beginStroke().moveTo(-960,40).lineTo(-960,-40).lineTo(960,-40).lineTo(960,40).closePath();
	this.shape_1.setTransform(960,-40);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.shape_1},{t:this.shape}]}).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.menu_background, new cjs.Rectangle(-1,-81,1922,81), null);


(lib.logo_large = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// logo
	this.instance = new lib.ClipGroup();
	this.instance.setTransform(-28.95,33.65,0.9657,0.9657,0,0,0,297.4,420.9);

	this.instance_1 = new lib.CachedBmp_15();
	this.instance_1.setTransform(82.7,0.4,0.5,0.5);

	this.instance_2 = new lib.ClipGroup_1();
	this.instance_2.setTransform(-28.95,33.65,0.9657,0.9657,0,0,0,297.4,420.9);

	this.instance_3 = new lib.CachedBmp_14();
	this.instance_3.setTransform(0.05,0.45,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_3},{t:this.instance_2},{t:this.instance_1},{t:this.instance}]}).wait(1));

	this._renderFirstFrame();

}).prototype = getMCSymbolPrototype(lib.logo_large, new cjs.Rectangle(-316.1,-372.8,574.8,813), null);


(lib.arrow_up = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_9 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).wait(9).call(this.frame_9).wait(1));

	// arrow
	this.instance = new lib.arrow("synched",0);
	this.instance.setTransform(0,12);

	this.timeline.addTween(cjs.Tween.get(this.instance).to({y:-26},5).to({y:-12},2).to({x:0.3,y:-18.4},2).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(-24,-38,48.3,62);


(lib.menu_marker = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {"capa":0,"projeto":1,"localizacao":2,"projetistas":3,"arquitetura":4,"implantacoes":5,"plantas":6,"sustentabilidade":7,"diferenciais":8,imagens:9,"institucional":10};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	// timeline functions:
	this.frame_0 = function() {
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(11));

	// arrow_up
	this.instance = new lib.arrow_up();
	this.instance.setTransform(205.7,0);
	this.instance.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance).to({_off:true},1).wait(10));

	// stripe_up
	this.occ_animate = new lib.stripe_up();
	this.occ_animate.name = "occ_animate";
	this.occ_animate.setTransform(158.65,0);

	this.timeline.addTween(cjs.Tween.get(this.occ_animate).wait(1).to({regX:0.1,scaleX:1.279,x:279.05},0).wait(1).to({regX:0,scaleX:1.7682,x:427.95},0).wait(1).to({regX:0.1,scaleX:1.636,x:590,y:15},0).wait(1).to({scaleX:1.7011,x:750.35,y:0},0).wait(1).to({scaleX:1.8711,x:923.75},0).wait(1).to({scaleX:1.2608,x:1076.3},0).wait(1).to({scaleX:2.1822,x:1240.95},0).wait(1).to({regX:0.2,scaleX:1.6531,x:1426.65},0).wait(1).to({scaleX:1.6058,x:753.5},0).wait(1).to({regX:0.4,scaleX:1.7755,x:1592.95},0).wait(1));

	this._renderFirstFrame();

}).prototype = p = new cjs.MovieClip();
p.nominalBounds = new cjs.Rectangle(117.6,0,1547.6000000000001,105);


// stage content:
(lib.index = function(mode,startPosition,loop,reversed) {
if (loop == null) { loop = true; }
if (reversed == null) { reversed = false; }
	var props = new Object();
	props.mode = mode;
	props.startPosition = startPosition;
	props.labels = {};
	props.loop = loop;
	props.reversed = reversed;
	cjs.MovieClip.apply(this,[props]);

	this.actionFrames = [0];
	this.isSingleFrame = false;
	// timeline functions:
	this.frame_0 = function() {
		if(this.isSingleFrame) {
			return;
		}
		if(this.totalFrames == 1) {
			this.isSingleFrame = true;
		}
		/**
		 *
		 *  Stop the movie clip.
		 */
		this.stop();
		occ_logo_large: false;
		/**
		 *
		 * Class reference.
		 */
		const AllTap = window.AllTap;
		
		/**
		 *
		 *  @param object
		 *  Set "this", object of canvas CreateJs.
		 */
		AllTap.setState({...AllTap.state, _this_index: this});
		
		/**
		 *
		 * @param array
		 *  [{
		 *      name: {string} = Name of occurrence not prefixed here "btn_".
		 *      occ_logo_large: {boolean} = Is visible (optional).
		 *      occ_logo_small: {boolean} = Is visible (optional).
		 *      occ_background_effect: {boolean} = Is visible (optional).
		 *      occ_video: {boolean} = Is button video, run outside the iFrame (optional).
		 *      occ_tour: {boolean} = Is button tour, run outside the iFrame (optional).
		 *      page_default: {boolean} = Define function $page_default() "root", finish of video (optional).
		 *  }]
		 */
		AllTap.createMenu([
		    {
		        name: 'capa',
		        occ_logo_large: false,
		        occ_logo_small: false,
		        occ_background: true,
		        page_default: true
		    },
		    {
		        name: 'projeto',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'localizacao',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'projetistas',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'arquitetura',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'implantacoes',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'plantas',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'sustentabilidade',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'diferenciais',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'imagens',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    },
		    {
		        name: 'institucional',
		        occ_logo_large: true,
		        occ_logo_small: false,
		        occ_background: false
		    }
		]);
	}

	// actions tween:
	this.timeline.addTween(cjs.Tween.get(this).call(this.frame_0).wait(1));

	// LogoLarge
	this.occ_logo_large = new lib.logo_large();
	this.occ_logo_large.name = "occ_logo_large";
	this.occ_logo_large.setTransform(114.9,1012.2,0.8379,0.8378,0,0,0,0,0.2);

	this.timeline.addTween(cjs.Tween.get(this.occ_logo_large).wait(1));

	// LogoSmall
	this.occ_logo_small = new lib.logo_small();
	this.occ_logo_small.name = "occ_logo_small";
	this.occ_logo_small.setTransform(103.4,1013.45,0.7194,0.7189);
	this.occ_logo_small.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.occ_logo_small).wait(1));

	// SignOutButton
	this.btn_sign_out = new lib.botoes();
	this.btn_sign_out.name = "btn_sign_out";
	this.btn_sign_out.setTransform(1870,1000,0.1059,0.5127);
	this.btn_sign_out.visible = false;
	new cjs.ButtonHelper(this.btn_sign_out, 0, 1, 2, false, new lib.botoes(), 3);

	this.timeline.addTween(cjs.Tween.get(this.btn_sign_out).wait(1));

	// sign_out_text
	this.instance = new lib.sign_out_text();
	this.instance.setTransform(1900,1040,1,1,0,0,0,13.2,18.7);
	this.instance.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

	// sign_out_background
	this.instance_1 = new lib.sign_out_background();
	this.instance_1.setTransform(1900,1040,1,1,0,0,0,20,30);
	this.instance_1.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance_1).wait(1));

	// MenuButton
	this.btn_imagens = new lib.botoes();
	this.btn_imagens.name = "btn_imagens";
	this.btn_imagens.setTransform(911.65,1010,0.2203,0.3844,0,0,0,0.9,0);
	new cjs.ButtonHelper(this.btn_imagens, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_diferenciais = new lib.botoes();
	this.btn_diferenciais.name = "btn_diferenciais";
	this.btn_diferenciais.setTransform(1572.4,1010,0.2875,0.3844,0,0,0,0.5,0);
	new cjs.ButtonHelper(this.btn_diferenciais, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_institucional = new lib.botoes();
	this.btn_institucional.name = "btn_institucional";
	this.btn_institucional.setTransform(1730.45,1010,0.2958,0.3844,0,0,0,0.8,0);
	new cjs.ButtonHelper(this.btn_institucional, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_capa = new lib.botoes();
	this.btn_capa.name = "btn_capa";
	this.btn_capa.setTransform(328.95,1010,0.1739,0.3844,0,0,0,1.4,0);
	new cjs.ButtonHelper(this.btn_capa, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_localizacao = new lib.botoes();
	this.btn_localizacao.name = "btn_localizacao";
	this.btn_localizacao.setTransform(565.5,1010,0.3075,0.3846,0,0,0,0.5,0);
	new cjs.ButtonHelper(this.btn_localizacao, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_projeto = new lib.botoes();
	this.btn_projeto.name = "btn_projeto";
	this.btn_projeto.setTransform(437.35,1010,0.2223,0.3846,0,0,0,0.7,0);
	new cjs.ButtonHelper(this.btn_projeto, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_plantas = new lib.botoes();
	this.btn_plantas.name = "btn_plantas";
	this.btn_plantas.setTransform(1233.5,1010,0.2192,0.3846,0,0,0,0.9,0);
	new cjs.ButtonHelper(this.btn_plantas, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_sustentabilidade = new lib.botoes();
	this.btn_sustentabilidade.name = "btn_sustentabilidade";
	this.btn_sustentabilidade.setTransform(1361.85,1010,0.3795,0.3844,0,0,0,0.6,0);
	new cjs.ButtonHelper(this.btn_sustentabilidade, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_implantacoes = new lib.botoes();
	this.btn_implantacoes.name = "btn_implantacoes";
	this.btn_implantacoes.setTransform(1053.4,1010,0.3253,0.3844,0,0,0,0.6,0);
	new cjs.ButtonHelper(this.btn_implantacoes, 0, 1, 2, false, new lib.botoes(), 3);

	this.btn_projetistas = new lib.botoes();
	this.btn_projetistas.name = "btn_projetistas";
	this.btn_projetistas.setTransform(737.45,1010,0.2846,0.3844,0,0,0,0.9,0);
	new cjs.ButtonHelper(this.btn_projetistas, 0, 1, 2, false, new lib.botoes(), 3);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.btn_projetistas},{t:this.btn_implantacoes},{t:this.btn_sustentabilidade},{t:this.btn_plantas},{t:this.btn_projeto},{t:this.btn_localizacao},{t:this.btn_capa},{t:this.btn_institucional},{t:this.btn_diferenciais},{t:this.btn_imagens}]}).wait(1));

	// MenuPseudoAfter
	this.instance_2 = new lib.pseudo_after();
	this.instance_2.setTransform(968.15,1015,0.965,1,0,0,0,0.2,0);
	this.instance_2.visible = false;

	this.timeline.addTween(cjs.Tween.get(this.instance_2).wait(1));

	// MenuText
	this.instance_3 = new lib.CachedBmp_12();
	this.instance_3.setTransform(1223,1030,0.5,0.5);

	this.instance_4 = new lib.CachedBmp_11();
	this.instance_4.setTransform(1582.3,1028.5,0.5,0.5);

	this.instance_5 = new lib.CachedBmp_10();
	this.instance_5.setTransform(1742.25,1028.5,0.5,0.5);

	this.instance_6 = new lib.CachedBmp_9();
	this.instance_6.setTransform(421.9,1030,0.5,0.5);

	this.instance_7 = new lib.CachedBmp_8();
	this.instance_7.setTransform(907.25,1028.5,0.5,0.5);

	this.instance_8 = new lib.CachedBmp_7();
	this.instance_8.setTransform(1251.1,1028.5,0.5,0.5);

	this.instance_9 = new lib.CachedBmp_6();
	this.instance_9.setTransform(748.55,1028.5,0.5,0.5);

	this.instance_10 = new lib.CachedBmp_5();
	this.instance_10.setTransform(450,1028.5,0.5,0.5);

	this.instance_11 = new lib.CachedBmp_4();
	this.instance_11.setTransform(579.25,1028.5,0.5,0.5);

	this.instance_12 = new lib.CachedBmp_3();
	this.instance_12.setTransform(1071.1,1028.5,0.5,0.5);

	this.instance_13 = new lib.CachedBmp_2();
	this.instance_13.setTransform(1378.85,1028.5,0.5,0.5);

	this.instance_14 = new lib.CachedBmp_1();
	this.instance_14.setTransform(343.65,1028.5,0.5,0.5);

	this.timeline.addTween(cjs.Tween.get({}).to({state:[{t:this.instance_14},{t:this.instance_13},{t:this.instance_12},{t:this.instance_11},{t:this.instance_10},{t:this.instance_9},{t:this.instance_8},{t:this.instance_7},{t:this.instance_6},{t:this.instance_5},{t:this.instance_4},{t:this.instance_3}]}).wait(1));

	// MenuMarker
	this.occ_menu_marker = new lib.menu_marker();
	this.occ_menu_marker.name = "occ_menu_marker";
	this.occ_menu_marker.setTransform(211.1,1080);
	this.occ_menu_marker.shadow = new cjs.Shadow("rgba(0,0,0,1)",0,-1,3);

	this.timeline.addTween(cjs.Tween.get(this.occ_menu_marker).wait(1));

	// MenuBackground
	this.occ_menu_background = new lib.menu_background();
	this.occ_menu_background.name = "occ_menu_background";
	this.occ_menu_background.setTransform(0,1080);

	this.timeline.addTween(cjs.Tween.get(this.occ_menu_background).wait(1));

	// background
	this.occ_background = new lib.background();
	this.occ_background.name = "occ_background";
	this.occ_background.setTransform(960,500,1,1,0,0,0,960,500);

	this.timeline.addTween(cjs.Tween.get(this.occ_background).wait(1));

	// BackgroundDynamic
	this.occ_background_dynamic = new lib.background_dynamic();
	this.occ_background_dynamic.name = "occ_background_dynamic";

	this.timeline.addTween(cjs.Tween.get(this.occ_background_dynamic).wait(1));

	this._renderFirstFrame();

}).prototype = p = new lib.AnMovieClip();
p.nominalBounds = new cjs.Rectangle(810.1,540,1110.9,840.8);
// library properties:
lib.properties = {
	id: '5F0EC95E53C3814DAF2FA395200D5FE6',
	width: 1920,
	height: 1080,
	fps: 30,
	color: "#B9B29B",
	opacity: 1.00,
	manifest: [
		{src:"./images/CachedBmp_9.png", id:"CachedBmp_9"},
		{src:"./images/index_atlas_1.png", id:"index_atlas_1"}
	],
	preloads: []
};



// bootstrap callback support:

(lib.Stage = function(canvas) {
	createjs.Stage.call(this, canvas);
}).prototype = p = new createjs.Stage();

p.setAutoPlay = function(autoPlay) {
	this.tickEnabled = autoPlay;
}
p.play = function() { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
p.stop = function(ms) { if(ms) this.seek(ms); this.tickEnabled = false; }
p.seek = function(ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
p.getDuration = function() { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

p.getTimelinePosition = function() { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

an.bootcompsLoaded = an.bootcompsLoaded || [];
if(!an.bootstrapListeners) {
	an.bootstrapListeners=[];
}

an.bootstrapCallback=function(fnCallback) {
	an.bootstrapListeners.push(fnCallback);
	if(an.bootcompsLoaded.length > 0) {
		for(var i=0; i<an.bootcompsLoaded.length; ++i) {
			fnCallback(an.bootcompsLoaded[i]);
		}
	}
};

an.compositions = an.compositions || {};
an.compositions['5F0EC95E53C3814DAF2FA395200D5FE6'] = {
	getStage: function() { return exportRoot.stage; },
	getLibrary: function() { return lib; },
	getSpriteSheet: function() { return ss; },
	getImages: function() { return img; }
};

an.compositionLoaded = function(id) {
	an.bootcompsLoaded.push(id);
	for(var j=0; j<an.bootstrapListeners.length; j++) {
		an.bootstrapListeners[j](id);
	}
}

an.getComposition = function(id) {
	return an.compositions[id];
}


an.makeResponsive = function(isResp, respDim, isScale, scaleType, domContainers) {		
	var lastW, lastH, lastS=1;		
	window.addEventListener('resize', resizeCanvas);		
	resizeCanvas();		
	function resizeCanvas() {			
		var w = lib.properties.width, h = lib.properties.height;			
		var iw = window.innerWidth, ih=window.innerHeight;			
		var pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;			
		if(isResp) {                
			if((respDim=='width'&&lastW==iw) || (respDim=='height'&&lastH==ih)) {                    
				sRatio = lastS;                
			}				
			else if(!isScale) {					
				if(iw<w || ih<h)						
					sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==1) {					
				sRatio = Math.min(xRatio, yRatio);				
			}				
			else if(scaleType==2) {					
				sRatio = Math.max(xRatio, yRatio);				
			}			
		}
		domContainers[0].width = w * pRatio * sRatio;			
		domContainers[0].height = h * pRatio * sRatio;
		domContainers.forEach(function(container) {				
			container.style.width = w * sRatio + 'px';				
			container.style.height = h * sRatio + 'px';			
		});
		stage.scaleX = pRatio*sRatio;			
		stage.scaleY = pRatio*sRatio;
		lastW = iw; lastH = ih; lastS = sRatio;            
		stage.tickOnUpdate = false;            
		stage.update();            
		stage.tickOnUpdate = true;		
	}
}
an.handleSoundStreamOnTick = function(event) {
	if(!event.paused){
		var stageChild = stage.getChildAt(0);
		if(!stageChild.paused || stageChild.ignorePause){
			stageChild.syncStreamSounds();
		}
	}
}
an.handleFilterCache = function(event) {
	if(!event.paused){
		var target = event.target;
		if(target){
			if(target.filterCacheList){
				for(var index = 0; index < target.filterCacheList.length ; index++){
					var cacheInst = target.filterCacheList[index];
					if((cacheInst.startFrame <= target.currentFrame) && (target.currentFrame <= cacheInst.endFrame)){
						cacheInst.instance.cache(cacheInst.x, cacheInst.y, cacheInst.w, cacheInst.h);
					}
				}
			}
		}
	}
}


})(createjs = createjs||{}, AdobeAn = AdobeAn||{});
//var createjs, AdobeAn;